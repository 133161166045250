.choose_day_xs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    font-size: 16px;
    color: #000;
}
.item-xs{
   background: #fff;
   border-radius: 5px;
   box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
   margin-top: 20px;
   font-size: 14px;
   padding:20px;
   text-align: left;
   position: relative;
}
.item-xs > div:first-child {
    font-size: 18px;
    color: #333;
    font-weight: 600;
}
.item-xs > div:nth-child(2) {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
}
select#date {
    border: 1px solid #bfbfbf;
    padding: 3px 6px;
    border-radius: 3px;
}
select#date:focus, select#date:active {
    border: 1px solid #bfbfbf;
    box-shadow: none;
    outline: none;
}
.item-xs img {
    height: 70px;
    width: 70px;
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 10px;
}